import Address from './Address'
import JobStatus, { JobStatusColor } from './JobStatus'
import LineItem from './LineItem'
import Attachment from './Attachment'

export interface BoardItem {
    name: string
    date: number
    status: JobStatus
}

export function BoardItemColor(boardItem: BoardItem): string {
    return JobStatusColor(boardItem.status, boardItem.date <= 0 && boardItem.status == JobStatus.Upcoming)
}

export interface Invoice {
    id: string
    status: JobStatus
    address: Address
    customerId: String
    attachments: Attachment[]
    lineItems: LineItem[]
    jobIds: string[]
    boardItems: BoardItem[] | null
}
