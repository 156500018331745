import JobStatus from '../../models/JobStatus'
import { Invoice } from '../../models/Invoice'
import { CalendarIcon } from '../icons/CalendarIcon'
import { CheckCircleIcon } from '../icons/CheckCircle'
import { SparklesIcon } from '../icons/SparklesIcon'
import Address from '../../models/Address'
import useNav, { useGoToDetail } from '../../hooks/NavigationHook'
import Tooltip from '../tooltip/Tooltip'
import MoreButton from '../moreButton/MoreButton'
import { useContext } from 'react'
import { IsEmployeeProvider } from '../../contexts/AuthContext'

interface JobRowProps {
    invoice: Invoice
}
const JobRow: React.FC<JobRowProps> = ({ invoice }) => {
    let navigate = useNav()
    let isEmployee = useContext(IsEmployeeProvider)

    let goToDetail = useGoToDetail()
    let completedJobCount = invoice.lineItems.filter((lineItem) => {
        return lineItem.status == 'completed'
    }).length

    let upcomingCount = invoice.lineItems.filter((lineItem) => {
        return lineItem.status == 'upcoming'
    }).length

    let inprogress = invoice.lineItems.filter((lineItem) => {
        return lineItem.status == 'inProgress'
    }).length
    return (
        <div
            key={invoice.id}
            className="w-full px-5"
            onClick={() => {
                goToDetail(invoice.id)
            }}
        >
            <div className="flex p-5 flex-row justify-between cursor-pointer hover:bg-gray-100 rounded-lg">
                <h1 className="">
                    <strong>{invoice.address.street}</strong>
                    <br />
                    {getAddressLine2(invoice.address)}
                </h1>
                <div className="flex flex-row items-center justify-center">
                    {invoice.status === JobStatus.Completed && (
                        <Tooltip content="Complete">
                            <div className="flex flex-row justify-center pr-2">
                                <CheckCircleIcon className="fill-green-400 w-5 pr-1" />
                                {completedJobCount}
                            </div>
                        </Tooltip>
                    )}
                    {invoice.status === JobStatus.InProgress && (
                        <div className="flex flex-row justify-center">
                            <Tooltip content="Upcoming">
                                <div className="flex flex-row justify-center pr-2">
                                    <CalendarIcon className="fill-yellow-400 w-5 pr-1" />
                                    {upcomingCount}
                                </div>{' '}
                            </Tooltip>
                            <Tooltip content="In Progress">
                                <div className="flex flex-row justify-center pr-2">
                                    <SparklesIcon className="fill-blue-400 w-5 pr-1" />
                                    {inprogress}
                                </div>{' '}
                            </Tooltip>
                            <Tooltip content="Complete">
                                <div className="flex flex-row justify-center pr-2">
                                    <CheckCircleIcon className="fill-green-400 w-5 pr-1" />
                                    {completedJobCount}
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    {invoice.status === JobStatus.Upcoming && (
                        <Tooltip content="Upcoming">
                            <div className="flex flex-row justify-center pr-2">
                                <CalendarIcon className='fill-black" w-5 pr-1' />
                                {upcomingCount}
                            </div>
                        </Tooltip>
                    )}
                    {isEmployee && (
                        <MoreButton>
                            <a
                                className="w-full"
                                href={invoice.jobIds.length > 0 ? 'https://pro.housecallpro.com/pro/jobs/' + invoice.jobIds[0] : ''}
                                target="_blank"
                            >
                                Open in HCP
                            </a>
                        </MoreButton>
                    )}
                </div>
            </div>
        </div>
    )
}

function getAddressLine2(address: Address): string {
    return `${address.city}, ${address.state}, ${address.zip}`
}

export default JobRow
