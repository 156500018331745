import { useNavigate } from 'react-router-dom'
import LoggedOutComponent from './LoggedOutComponent'
import useNav from '../hooks/NavigationHook'
import { useContext, useEffect } from 'react'
import { GetCustomerProvider } from '../contexts/AppContext'
import { IsEmployeeProvider } from '../contexts/AuthContext'

function SlashRedirect() {
    const navigate = useNav()
    let isEmployee = useContext(IsEmployeeProvider)
    let customer = useContext(GetCustomerProvider)

    useEffect(() => {
        if (isEmployee) {
            navigate('/admin/home')
        } else {
            navigate('/' + customer?.id)
        }
    }, [])

    return <h1 className="min-h-screen"></h1>
}

export default SlashRedirect
