import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CustomerPortalRoutes from './CustomerPortalRoutes'
import ScrollToTop from './components/scrollToTop/ScrollsToTop'
import { AppDataContext } from './contexts/AppContext'
import { NavigationContext } from './contexts/NavigationContext'
import { AuthContext } from './contexts/AuthContext'
import { AxiosSessionContext } from './contexts/AxiosSessionContext'
import { CustomerListContext } from './contexts/CustomerContext'

const RootComponent = () => {
    return (
        <Router>
            <AuthContext>
                <CustomerListContext>
                    <NavigationContext>
                        <AppDataContext>
                            <AxiosSessionContext>
                                <ScrollToTop />
                                <CustomerPortalRoutes />
                            </AxiosSessionContext>
                        </AppDataContext>
                    </NavigationContext>
                </CustomerListContext>
            </AuthContext>
        </Router>
    )
}

export default RootComponent
