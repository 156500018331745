import React, { createContext, useState, ReactNode, useContext } from 'react'

import { AxiosError, AxiosResponse } from 'axios'

import axios from 'axios'

import LoginResponse from '../api/Login/LoginResponse'
import { useLocation } from 'react-router-dom'
import useNav from '../hooks/NavigationHook'
import Customer from '../models/Customer'
import { CACHE } from './Cache'
import useParamsHook from '../hooks/GetParamsHook'
import { UpdateEmployeeStatus } from './AuthContext'

const RegisterProvider = createContext<(email: string, password: string) => Promise<boolean>>(() => {
    return new Promise((resolve, reject) => {
        resolve(false)
    })
})

const LoginProvider = createContext<(email: string, password: string) => Promise<boolean>>(() => {
    return new Promise((resolve, reject) => {
        resolve(false)
    })
})

const LogOutProvider = createContext<() => Promise<void>>(() => {
    return new Promise((resolve, reject) => {
        resolve()
    })
})

const AcceptInviteProvider = createContext<(inviteId: string, password: string) => Promise<boolean>>(() => {
    return new Promise((resolve, reject) => {
        resolve(false)
    })
})

const ForgotPasswordConfirmProvider = createContext<(forgotPasswordCode: string, password: string) => Promise<boolean>>(() => {
    return new Promise((resolve, reject) => {
        resolve(false)
    })
})

const ClearNetworkCacheProvider = createContext<() => Promise<void>>(() => {
    return new Promise((resolve, reject) => {
        resolve()
    })
})

const GetCustomerProvider = createContext<Customer | null>(null)

const IsLoggedInProvider = createContext(false)
const IsAdminProvider = createContext(false)

interface AppDataContextProps {
    children: ReactNode
}

const AppDataContext: React.FC<AppDataContextProps> = ({ children }) => {
    let navigate = useNav()
    let location = useLocation()
    let [loggedIn, setLoggedIn] = useState(localStorage.getItem('session') != null)
    let updateEmployeeState = useContext(UpdateEmployeeStatus)
    let params = useParamsHook()
    console.log(params)
    const login = async (email: string, password: string) => {
        try {
            const result = (
                await axios.post('/v1/login', {
                    email: email,
                    password: password
                })
            ).data as LoginResponse

            localStorage.setItem('session', JSON.stringify(result))
            localStorage.setItem('user_id', result.user_id)
            localStorage.setItem('role', result.role)
            setLoggedIn(true)
            updateEmployeeState()
            navigate(location.pathname, { replace: true })
            return true
        } catch (error: any) {
            const err: AxiosError = error
            console.error(err.response?.data)
            setLoggedIn(false)
            updateEmployeeState()
            // You can handle the error here, e.g., show an error message to the user
            return false // Login failed
        }
    }

    const register = async (email: string, password: string) => {
        const result = (
            await axios.post('/v1/register', {
                email: email,
                password: password
            })
        ).data as LoginResponse
        localStorage.setItem('session', JSON.stringify(result))
        localStorage.setItem('user_id', result.user_id)
        localStorage.setItem('role', result.role)
        setLoggedIn(true)
        updateEmployeeState()
        navigate(location.pathname, { replace: true })
        return true
    }

    const acceptInvite = async (inviteCode: string, password: string) => {
        try {
            const result = (
                await axios.post('/v1/invite/accept', {
                    inviteCode: inviteCode,
                    password: password
                })
            ).data as LoginResponse
            localStorage.setItem('session', JSON.stringify(result))
            localStorage.setItem('user_id', result.user_id)
            localStorage.setItem('role', result.role)
            setLoggedIn(true)
            updateEmployeeState()
            navigate(location.pathname, { replace: true })
            return true
        } catch (error: any) {
            const err: AxiosError = error
            console.error(err.response?.data)
            setLoggedIn(false)
            updateEmployeeState()
            // You can handle the error here, e.g., show an error message to the user
            return false // Login failed
        }
    }

    const getCustomer = () => {
        let sessionString = localStorage.getItem('session')
        if (sessionString != null) {
            return JSON.parse(sessionString)['customer']
        }
        return null
    }

    const forgotPasswordConfirm = async (code: string, password: string) => {
        try {
            const result = (
                await axios.post('/v1/forgotPassword/confirm', {
                    code: code,
                    password: password
                })
            ).data as LoginResponse
            localStorage.setItem('session', JSON.stringify(result))
            localStorage.setItem('user_id', result.user_id)
            localStorage.setItem('role', result.role)
            setLoggedIn(true)
            updateEmployeeState()
            navigate('/', { replace: true })
            return true
        } catch (error: any) {
            const err: AxiosError = error
            console.error(err.response?.data)
            setLoggedIn(false)
            updateEmployeeState()
            // You can handle the error here, e.g., show an error message to the user
            return false // Login failed
        }
    }

    const clearNetworkCache = async () => {
        CACHE['urlCache'] = {}
    }

    const logout = async () => {
        localStorage.removeItem('session')
        localStorage.removeItem('user_id')
        localStorage.removeItem('role')
        navigate('/')
        setLoggedIn(false)
        updateEmployeeState()
    }
    let isAdmin = false

    let sessionData = localStorage.getItem('session')
    if (sessionData != null) {
        isAdmin = JSON.parse(sessionData)['role']?.toLowerCase() == 'admin'
    }

    return (
        <GetCustomerProvider.Provider value={getCustomer()}>
            <ClearNetworkCacheProvider.Provider value={clearNetworkCache}>
                <ForgotPasswordConfirmProvider.Provider value={forgotPasswordConfirm}>
                    <AcceptInviteProvider.Provider value={acceptInvite}>
                        <IsAdminProvider.Provider value={isAdmin}>
                            <IsLoggedInProvider.Provider value={loggedIn}>
                                <LogOutProvider.Provider value={logout}>
                                    <RegisterProvider.Provider value={register}>
                                        <LoginProvider.Provider value={login}>{children}</LoginProvider.Provider>
                                    </RegisterProvider.Provider>
                                </LogOutProvider.Provider>
                            </IsLoggedInProvider.Provider>
                        </IsAdminProvider.Provider>
                    </AcceptInviteProvider.Provider>
                </ForgotPasswordConfirmProvider.Provider>
            </ClearNetworkCacheProvider.Provider>
        </GetCustomerProvider.Provider>
    )
}

export {
    AcceptInviteProvider,
    AppDataContext,
    GetCustomerProvider,
    RegisterProvider,
    LoginProvider,
    LogOutProvider,
    IsLoggedInProvider,
    IsAdminProvider,
    ForgotPasswordConfirmProvider,
    ClearNetworkCacheProvider
}
