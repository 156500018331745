import React, { useState } from 'react'

interface TooltipProps {
    children: React.ReactNode
    content: React.ReactNode
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div className="relative flex items-center">
            <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {children}
            </div>
            {isHovered && <div className="absolute bottom-full mb-2 px-3 py-2 bg-gray-700 text-white text-sm rounded-md shadow-md">{content}</div>}
        </div>
    )
}

export default Tooltip
