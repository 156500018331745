import { useContext } from 'react'
import HomeDashboardPage from '../pages/HomeDashboardPage'
import NotFound from '../pages/NotFound'
import ProjectDetailPage from '../pages/ProjectDetailPage'
import AdminHomePage from '../pages/admin/AdminHome'
import ForgotPasswordConfirmPage from '../pages/login/ForgotPasswordConfirmPage'
import ForgotPasswordPage from '../pages/login/ForgotPasswordPage'
import InviteRegister from '../pages/login/InviteRegister'
import LoginPage from '../pages/login/LoginPage'
import ResetPasswordPage from '../pages/login/ResetPasswordPage'
import AddTeamMemberPage from '../pages/settings/AddTeamMemberPage'
import SettingsPage from '../pages/admin/CustomerSettingsPage'
import AppRoute from './router/route'
import SlashRedirect from '../pages/SlashRedirect'
import AdminPortalSettings from '../pages/admin/PortalSettings'
import AddMappingPage from '../pages/admin/AddMappingPage'
import CommunicationPreferencesPage from '../pages/settings/CommunicationPreferencesPage'
import UnsubscribeFromEmail from '../pages/login/UnsubscribeFromEmail'
import CustomerSettingsPage from '../pages/admin/CustomerSettingsPage'
import PhoneNumberValidationPage from '../pages/settings/PhoneNumberValidationInput'
import MakeReadyPage from '../pages/makeReadyBoard/MakeReadyBoardPage'
import AdminMakeReadyPage from '../pages/admin/AllInvoiceBoard'
import EditMappingOrderPage from '../pages/admin/MappingOrder'
import AddEmployeeModal from '../pages/admin/AddEmployeeModel'

type Routes = {
    [key: string]: AppRoute
}

// All routes that should work before the user is logged in.
export const PREAUTH_ROUTES: Routes = {
    // We want any unmatched route to show login.
    // When the user logs in it will either take them to
    // a real route a 404 not found route
    LOGIN_ROUTE: {
        name: 'Not found',
        route: '*',
        component: LoginPage
    },
    FORGOT_ROUTE: {
        name: 'forgot',
        route: '/forgot',
        headerInfo: null,
        component: ForgotPasswordPage
    },
    FORGOT_CONFIRM_ROUTE: {
        name: 'forgot',
        route: '/forgot/:code',
        headerInfo: null,
        component: ForgotPasswordConfirmPage
    },
    INVITE_ROUTE: {
        name: 'invite',
        route: '/invite/:inviteId',
        headerInfo: null,
        component: InviteRegister
    },
    RESET_ROUTE: {
        name: 'reset',
        route: '/reset/:inviteId',
        headerInfo: null,
        component: ResetPasswordPage
    },
    UNSUBSCRIBE_ROUTE: {
        name: 'reset',
        route: '/unsubscribe/email/:code',
        headerInfo: null,
        component: UnsubscribeFromEmail
    }
}

// All routes that should work after the user is logged in.
export const AUTH_ROUTES: Routes = {
    HOME_DASHBOARD_PAGE: {
        name: 'homeDashboard',
        route: '/',
        headerInfo: {
            backVisible: false,
            title: 'Dashboard',
            customTitle: (customer) => {
                return customer?.company ?? ''
            }
        },
        component: SlashRedirect
    },
    MAKE_READY_BOARD: {
        name: 'makeReady',
        route: '/:customerId/makeReady',
        headerInfo: {
            backVisible: true,
            title: 'Dashboard',
            customTitle: (customer) => {
                return customer?.company ?? ''
            },
            handleBack: (route) => {
                route('/')
            }
        },
        component: MakeReadyPage,
        footerHidden: true
    },
    UNSUBSCRIBE_ROUTE: {
        name: 'unsubscribe',
        route: '/unsubscribe/email/:code',
        headerInfo: null,
        component: UnsubscribeFromEmail
    },
    Admin_HOME_DASHBOARD_PAGE: {
        name: 'homeDashboard',
        route: '/:customerId',
        headerInfo: {
            backVisible: false,
            title: 'Dashboard',
            customTitle: (customer) => {
                return customer?.company ?? ''
            }
        },
        component: HomeDashboardPage
    },
    HOME_DASHBOARD_DETAIL_PAGE: {
        name: 'homeDashboard',
        route: '/:customerId/project/:projectId',
        headerInfo: {
            backVisible: true,
            title: 'Detail',
            handleBack: (route) => {
                route('')
            },
            customTitle: (customer) => {
                return customer?.company ?? ''
            }
        },
        component: ProjectDetailPage
    },
    VERIFY_PHONE_NUMBER: {
        name: 'communicationPreferences',
        route: '/verifyPhoneNumber',
        headerInfo: {
            backVisible: true,
            title: 'Verify Phone Number',
            handleBack: (route) => {
                route('')
            },
            customTitle: (customer) => {
                return customer?.company ?? ''
            }
        },
        component: PhoneNumberValidationPage
    },
    COMMUNICATION_PREFERENCES: {
        name: 'communicationPreferences',
        route: '/:customerId/settings/communicationPreferences',
        headerInfo: {
            backVisible: true,
            title: 'Communication Preferences',
            handleBack: (route) => {
                route('')
            },
            customTitle: (customer) => {
                return customer?.company ?? ''
            }
        },
        component: CommunicationPreferencesPage
    },
    NOT_FOUND: {
        name: 'Not found',
        route: '*',
        component: NotFound
    }
}

// If the user is an employee then these routes will be available as well as all other AUTH_ROUTES routes
export const AUTH_EMPLOYEE_ROUTES: Routes = {
    ADMIN_ADD_MAKEREADY_MAPPING: {
        name: 'makereadymapping',
        route: '/makereadymapping',
        headerInfo: {
            backVisible: true,
            title: 'Make Ready Mapping',
            handleBack: (route) => {
                route('')
            }
        },
        component: AddMappingPage
    },
    ADMIN_ADD_EMPLOYEES: {
        name: 'AddEmployee',
        route: '/addEmployee',
        component: AddEmployeeModal
    },
    ADMIN_MAKE_READY_BOARD: {
        name: 'makereadymapping',
        route: '/admin/home/makeReadyBoard',
        headerInfo: {
            backVisible: true,
            title: 'Make Ready Board',
            handleBack: (route) => {
                route('/admin/home')
            }
        },
        component: AdminMakeReadyPage,
        footerHidden: true
    },
    ADMIN_EDIT_MAKEREADY_MAPPING: {
        name: 'editMakereadymapping',
        route: '/makereadymapping/:mapId',
        headerInfo: {
            backVisible: true,
            title: 'Edit Make Ready Mapping',
            handleBack: (route) => {
                route('')
            }
        },
        component: AddMappingPage
    },
    ADMIN_EDIT_MAPPINGS: {
        name: 'editMappings',
        route: '/editMappingOrder',
        component: EditMappingOrderPage
    },
    ADMIN_ADD_EMPLOYEE: {
        name: 'member',
        route: '/addEmployee',
        headerInfo: {
            backVisible: true,
            title: 'Member',
            handleBack: (route) => {
                route('')
            }
        },
        component: AddTeamMemberPage
    },
    ADMIN_PORTAL_SETTINGS: {
        name: 'Portal Settings',
        route: '/admin/home/portalSettings',
        component: AdminPortalSettings,
        headerInfo: {
            backVisible: false,
            title: 'Portal Settings',
            handleBack: (route) => {
                route('/admin/home')
            }
        }
    },
    ADMIN_HOME: {
        name: 'Admin home',
        route: '/admin/home',
        component: AdminHomePage,
        headerInfo: {
            backVisible: false,
            title: 'Admin Home'
        }
    },
    TEAM_MEMBER_ROUTE: {
        name: 'member',
        route: '/:customerId/addTeamMember',
        headerInfo: {
            backVisible: true,
            title: 'Member',
            handleBack: (route) => {
                route('')
            }
        },
        component: AddTeamMemberPage
    },
    SETTINGS_ROUTE: {
        name: 'settings',
        route: '/:customerId/settings',
        headerInfo: {
            backVisible: true,
            title: 'Settings',
            handleBack: (route) => {
                route('')
            },
            customTitle: (customer) => {
                return customer?.company ?? ''
            }
        },
        component: CustomerSettingsPage
    }
}
