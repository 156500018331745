import LoggedOutComponent from '../LoggedOutComponent'
import axios from 'axios'
import { useState } from 'react'
import useNav from '../../hooks/NavigationHook'

function ForgotPasswordPage() {
    let navigate = useNav()
    let [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [email, setEmail] = useState('')

    const resetPasswordRequest = async () => {
        try {
            const response = await axios.post('/v1/forgotPassword', {
                email: email
            })
            setShowSuccessMessage(true)
        } catch (error: any) {
            setErrorMessage('Failed to send reset code')
        }
    }
    return (
        <LoggedOutComponent>
            <form>
                <div className="flex flex-col items-center justify-center mb-4">
                    <h1 className="text-text text-xl">Enter your email below and we will send you instructions</h1>
                </div>

                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} // Update the state on user input
                        placeholder="Email"
                    />
                </div>
                {errorMessage !== '' && <p>{errorMessage}</p>}
                <div className="flex flex-col-reverse md:flex-row items-center justify-between">
                    <button
                        className="text-link font-bold py-2 px-4"
                        type="button"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Back to login
                    </button>
                    <button
                        className="bg-loginAction text-black font-bold py-2 px-4 focus:outline-none focus:shadow-outline  w-full md:w-40 rounded-full"
                        type="button"
                        onClick={() => {
                            resetPasswordRequest()
                        }}
                    >
                        Send Email
                    </button>
                </div>
            </form>
        </LoggedOutComponent>
    )
}

export default ForgotPasswordPage
