let GLOBAL_BASE_URL = 'https://api.dev.makereadyboard.com'

const DEV_BASE_URL = 'https://api.dev.makereadyboard.com'

const DomainPrefix = process.env.REACT_APP_DOMAIN_PREFIX

if (DomainPrefix != null) {
    GLOBAL_BASE_URL = 'https://api.' + DomainPrefix + '.dev.makereadyboard.com'
} else {
    GLOBAL_BASE_URL = DEV_BASE_URL
}

export default GLOBAL_BASE_URL
